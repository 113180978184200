<!-- Employee options inside side menu visible only for employees -->
<template>
  <WCSideMenuCollapsibleTemplate>
    <template v-slot:menuHeader>
      <h3 class="mb-0 text-dark">{{ $t('employeeActions') }}</h3>
    </template>
    <template v-slot:menuBody>
      <ul>
        <WCSideMenuItem>
          <router-link :to="{ name: 'EmployeeCustomerSelect' }"
            ><span class="underline-link-from-center">{{
              $t('associateAsCustomer')
            }}</span></router-link
          ></WCSideMenuItem
        >
        <WCSideMenuItem @click="onClickReplicateNow"
          ><span class="underline-link-from-center">{{ $t('replicateNow') }}</span></WCSideMenuItem
        >
        <WCSideMenuItem
          ><a :href="downloadLogPath"
            ><span class="underline-link-from-center">{{ $t('downloadLogs') }}</span></a
          ></WCSideMenuItem
        >
        <WCSideMenuItem @click="resetIndex" data-testid="resetIndexButton">
          <span class="underline-link-from-center">{{ $t('resetIndex') }}</span>
        </WCSideMenuItem>
      </ul>
    </template>
  </WCSideMenuCollapsibleTemplate>
</template>

<script>
import WCSideMenuCollapsibleTemplate from '../WCSideMenuCollapsibleTemplate/WCSideMenuCollapsibleTemplate.vue';
import EmployeeMixin from '../../../employee/mixins/EmployeeMixin';
import WCSideMenuItem from '../WCSideMenuItem/WCSideMenuItem.vue';

export default {
  name: 'WCEmployeeActionsCollapsibleMenu',
  components: { WCSideMenuCollapsibleTemplate, WCSideMenuItem },
  mixins: [EmployeeMixin], // mixin for replicateNow(), selectCustomerRoute and downloadLogPath
  methods: {
    onClickReplicateNow() {
      this.replicateNow();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
h3,
a {
  color: var(--dark, $dark);
}
</style>
