<template>
  <div :class="{ dropdown: showOrderTypeDropdown }" v-click-outside="hideDropdown">
    <div class="wc-atc-btn wc-atc-btn--basic" :class="{ 'btn-group': showOrderTypeDropdown }">
      <!-- If the item is available for other order types, dropdown with other types will be displayed -->
      <button
        v-if="showOrderTypeDropdown && !isScanAndGoMode"
        @click="toggleDropdown"
        class="btn btn-sm dropdown-toggle dropdown-toggle-split"
        :class="
          linkButtonType
            ? 'wc-add-to-cart__dropdown-link text-primary'
            : 'wc-add-to-cart__dropdown btn-primary'
        "
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      />

      <!-- Screen reader live region for quantity updates -->
      <span class="sr-only" aria-live="polite"
        >{{ $t('quantity') }} {{ getDisplayQuantityInCart(item) }}</span
      >

      <template v-if="listOnly">
        <WCAddToListButton
          v-if="!isMto"
          :item="item"
          :describedBy="item.id"
          :btnVarient="addToListInferredVariant"
        />
        <button
          v-else
          :class="
            addToListVariantIsMobileCard
              ? 'btn text-primary rounded add-to-list--btn-mobile'
              : 'mobile-btn btn btn-primary btn-block'
          "
          @click="customizeItemToAddToList()"
          ref="customizeBtnRef"
        >
          <span v-if="!addToListVariantIsMobileCard">
            {{ $t('customizeAndAddToList') }}
          </span>
          <font-awesome-icon class="ml-2" icon="list-check" />
        </button>
      </template>

      <button
        v-else-if="notAvailable"
        :id="'batcb_nabtn_' + item.id"
        class="mobile-btn btn btn-primary btn-block"
        disabled
      >
        {{ $t('notAvailable') }}
      </button>

      <button
        v-else-if="outOfStock"
        :id="'batcb_oosbtn_' + item.id"
        class="mobile-btn btn btn-primary btn-block"
        disabled
      >
        {{ $t('outOfStock') }}
      </button>

      <button
        v-else-if="inStoreOnly"
        :id="'batcb_isobtn_' + item.id"
        class="mobile-btn btn btn-primary btn-block"
        disabled
      >
        {{ $t('inStoreOnly') }}
      </button>

      <button
        v-else-if="notEligibleForCart"
        class="mobile-btn btn btn-block"
        :class="linkButtonType ? 'wc-add-to-cart-link__button text-primary p-0' : 'btn-primary'"
        @click.stop="toggleDropdown"
      >
        {{ $t('chooseCart') }}
      </button>

      <WCAddToCartQuantityAdjuster
        v-else-if="showAddToCartOption"
        :item="item"
        :linkButtonType="linkButtonType"
        :describedBy="describedBy"
        :showMobileCustomize="showMobileCustomize"
        :showOrderTypeDropdown="showOrderTypeDropdown"
        :variation="variation"
        :isListItem="isListItem"
        :priceEmbeddedLineItem="priceEmbeddedLineItem"
        :orderType="orderType"
        :entryMethod="entryMethod"
        class="btn-block"
        @addToCart="$emit('addToCart')"
        data-testid="quantityAdjusterButton"
      />
    </div>
    <div
      v-if="!isScanAndGoMode"
      class="dropdown-menu wc-cart-dropdown-menu"
      :class="{ show: showDropdown }"
      @keydown.esc="hideDropdown"
      aria-labelledby="dropdownMenuButton"
    >
      <WCOrderTypesAddToCartDropdown
        :item="item"
        :isListItem="isListItem"
        :variation="variation"
        :showMobileCustomize="showMobileCustomize"
        :orderTypes="eligibleInactiveOrderTypes"
        :entryMethod="entryMethod"
        @addToCart="$emit('addToCart')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters, mapActions } from 'vuex';
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import WCAddToCartQuantityAdjuster from '@/modules/cart/components/WCAddToCartQuantityAdjuster/WCAddToCartQuantityAdjuster.vue';
import WCOrderTypesAddToCartDropdown from '@/modules/orderTypes/components/WCOrderTypesAddToCartDropdown/WCOrderTypesAddToCartDropdown.vue';
import { BTN_VARIANT as ATLB_BTN_VARIANT } from '@/constants/AppConstants';
import WCAddToListButton from '@/modules/lists/components/WCAddToListButton/WCAddToListButton.vue';
import ProductDetailMixin from '@/views/ProductDetailPage/mixins/ProductDetailMixin';
import vClickOutside from 'click-outside-vue3';
import { defineComponent, PropType } from 'vue';
import { ENTRY_METHOD, EntryMethod } from '@/models/entities/cart/entry-method';

export default defineComponent({
  name: 'WCBasicAddToCartButton',
  emits: ['addToCart'],
  components: {
    WCAddToCartQuantityAdjuster,
    WCOrderTypesAddToCartDropdown,
    WCAddToListButton,
  },
  mixins: [CartControlsMixin, OrderTypesMixin, ProductDetailMixin],
  props: {
    showMobileCustomize: {
      type: Boolean,
    },
    describedBy: {
      type: String,
    },
    linkButtonType: {
      type: Boolean,
      default: false,
    },
    showAddToCartOption: {
      type: Boolean,
      default: true,
    },
    priceEmbeddedLineItem: {
      type: Object,
      required: false,
    },
    dropdownVariant: {
      type: Boolean,
      default: true,
    },
    variation: {},
    isListItem: {
      type: Boolean,
      default: false,
    },
    addToListVariant: {
      type: String,
      default: ATLB_BTN_VARIANT.ATC,
      required: false,
    },
    entryMethod: {
      type: String as PropType<EntryMethod>,
      required: false,
      default: ENTRY_METHOD.DEFAULT,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    ...mapGetters({
      isScanAndGoMode: 'user/isCustomerModeScan',
      isInStore: 'cart/getInStore',
    }),
    outOfStock() {
      return !this.isInStock(this.item) && !this.isScanAndGoMode;
    },
    inStoreOnly() {
      return this.isInStoreOnly(this.item) && !this.isScanAndGoMode;
    },
    isMto() {
      return this.item.hasModifiers || this.item.hasLinkedItems;
    },
    listOnly() {
      return this.isItemListOnly(this.item) && !this.isScanAndGoMode;
    },
    notAvailable() {
      return (
        (this.item.discontinued && this.outOfStock) ||
        (this.item.discontinued && this.inStoreOnly) ||
        this.getMinimumQuantity(this.item).comparedTo(0) <= 0
      );
    },
    notEligibleForCart() {
      return (
        this.$configuration.orderTypesEnabled && !this.isInActiveOrderType && !this.isScanAndGoMode
      );
    },
    isInActiveOrderType() {
      return (
        this.activeOrderType && this.isItemEligibleForOrderType(this.item, this.activeOrderType)
      );
    },
    eligibleOrderTypes() {
      return this.eligibleOrderTypesForItem(this.item);
    },
    eligibleInactiveOrderTypes() {
      return this.eligibleInactiveOrderTypesForItem(this.item);
    },
    /**
     * Only show the order type dropdown if there are other eligible order types & the item can be added to a cart normally
     */
    showOrderTypeDropdown() {
      return (
        this.dropdownVariant &&
        this.eligibleInactiveOrderTypes.length > 0 &&
        !this.outOfStock &&
        !this.inStoreOnly &&
        !this.notAvailable
      );
    },
    addToListInferredVariant() {
      if (this.linkButtonType) {
        return ATLB_BTN_VARIANT.LINK;
      }
      return this.addToListVariant;
    },
    addToListVariantIsMobileCard() {
      return this.addToListInferredVariant === ATLB_BTN_VARIANT.MOBILE_CARD;
    },
  },
  methods: {
    ...mapActions({
      setInStore: 'cart/setInStore',
      reload: 'cart/reload',
      sync: 'cart/sync',
    }),
    /**
     * Method to call on clicking add to cart button - check if user has scan and go session, increment and move focus to increment button if not
     */
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    hideDropdown() {
      this.showDropdown = false;
    },
    keepDropdown() {
      this.showDropdown = true;
    },
  },
});
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-atc-btn {
  display: flex;
}

.quantity-overlay {
  .mobile-btn {
    width: fit-content;
    padding: $pad-1 $pad-2;
    font-size: $font-size-xs;
    min-width: 0;
  }
}

.wc-quantity-adjuster__mobile {
  max-height: 2.5rem;
}

.wc-add-to-cart__dropdown {
  color: var(--primary-contrast, get-color-contrast('primary'));
  background-color: var(--primary, $primary);
  border-color: var(--primary, $primary);
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.wc-add-to-cart__dropdown-link {
  color: var(--primary, $primary);
  border: 0;
  margin-left: 0;
  margin-right: 0;
  justify-content: space-between;
  align-items: center;
}

.wc-cart-dropdown-menu {
  width: auto;

  right: 0;
  padding: 0;
  margin: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.add-to-list--btn-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}
</style>
