<template>
  <div v-click-outside="hide" class="dropdown" :class="{ show }" @keydown.esc="hide">
    <a
      id="login-dropdown"
      class="d-flex align-items-center dropdown-toggle text-body"
      href="#"
      role="button"
      aria-haspopup="true"
      :aria-expanded="show"
      data-toggle="dropdown"
      data-testid="login-dropdown"
      @click.prevent="toggle"
    >
      <div
        class="d-flex login-icon rounded-circle align-items-center justify-content-center bg-secondary mr-2"
      >
        <font-awesome-icon icon="right-to-bracket" size="lg" />
      </div>
      {{ $t('signIn') }}
    </a>
    <div class="dropdown-menu" role="menu" :class="{ show }" aria-labelledby="login-dropdown">
      <router-link class="dropdown-item" to="/login" data-testid="login-link">
        {{ $t('signIn') }}
      </router-link>
      <router-link
        class="dropdown-item"
        to="/register"
        data-testid="register-link"
        v-if="$configuration.allowCustomerAdd"
      >
        {{ $t('register') }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import vClickOutside from 'click-outside-vue3';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    hide() {
      this.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.login-icon {
  width: 3rem;
  height: 3rem;
  color: var(--secondary-contrast);
}
</style>
