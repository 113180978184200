<template>
  <WCModal position="center" @dismiss="dismiss(MODAL_EVENTS.DISMISS)">
    <WCModalHeader class="align-items-baseline">
      <div class="font-size-xl font-weight-bold">
        <font-awesome-icon
          v-if="headerIcon"
          class="mr-2"
          :icon="headerIcon"
          data-testid="headerIconLabel"
        />
        {{ title }}
      </div>
      <button
        @click="dismiss(MODAL_EVENTS.DISMISS)"
        class="btn btn-link underline-link-from-center"
      >
        {{ $t('close') }}
      </button>
    </WCModalHeader>

    <WCModalBody>
      <div>
        <div class="text-center">
          <font-awesome-icon
            :icon="summaryIcon"
            size="3x"
            class="w-100 mb-3"
            :class="iconColor"
            data-testid="summaryIconLabel"
          />
          <p class="font-size-lg">{{ summary }}</p>
          <p v-if="postSummary" class="font-size-md" data-testid="postSummaryLabel">
            {{ postSummary }}
          </p>
          <div v-for="(auth, authIndex) in ebtAuthList" :key="authIndex">
            <p class="font-weight-bold" data-testid="refundAmountLabel">
              {{ $t('ebtAmountRefundedToCard', { ending: auth.lastFour }) }}
              <span class="font-weight-bold" data-testid="refundAmount">
                {{ $filters.currency(auth.tenderAmount) }}
              </span>
            </p>
            <p data-testid="remainingBalanceLabel">
              {{ $t('ebtRemainingBalance', { type: ebtType(auth) }) }}
              <span class="font-weight-bold" data-testid="remainingBalance">
                {{ $filters.currency(auth.remainingBalance) }}
              </span>
            </p>
          </div>
        </div>
        <div class="mx-3 mt-2" v-if="endingNote">
          <span class="font-weight-bold">{{ $t('note') }} </span>
          {{ endingNote }}
        </div>
      </div>
    </WCModalBody>

    <WCModalFooter>
      <button @click="close(MODAL_EVENTS.CONFIRM)" class="btn btn-primary m-auto w-100">
        {{ $t('ok') }}
      </button>
    </WCModalFooter>
  </WCModal>
</template>

<script lang="ts">
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import { MODAL_EVENTS } from '@/constants/EventConstants';
import { EbtAuthInformation } from '@/models/entities/ebt-auth-information';
import { PropType, defineComponent } from 'vue';

export const REFUND_VARIANT = {
  ORDER_CANCELLED: 'ORDER_CANCELLED',
  ORDER_CHANGED_AFTER_TENDER: 'ORDER_CHANGED_AFTER_TENDER',
  TENDER_REMOVED: 'TENDER_REMOVED',
  TOKEN_REMOVED: 'TOKEN_REMOVED',
} as const;

type ObjectValues<T> = T[keyof T];

export type RefundVariant = ObjectValues<typeof REFUND_VARIANT>;

export default defineComponent({
  mixins: [ModalMixin],
  components: { WCModal, WCModalHeader, WCModalBody, WCModalFooter },
  props: {
    ebtAuthList: Array as PropType<Array<EbtAuthInformation>>,
    variant: {
      type: String as PropType<RefundVariant>,
      required: true,
    },
  },
  data() {
    return {
      MODAL_EVENTS,
      TITLES: {
        [REFUND_VARIANT.ORDER_CANCELLED]: this.$t('cancelOrderSuccess'),
        [REFUND_VARIANT.ORDER_CHANGED_AFTER_TENDER]: this.$t('ebtPaymentRefundedTitle'),
        [REFUND_VARIANT.TENDER_REMOVED]: this.$t('ebtRemovePaymentTitle'),
        [REFUND_VARIANT.TOKEN_REMOVED]: this.$t('deleteCard'),
      },
      SUMMARY_ICON: {
        [REFUND_VARIANT.ORDER_CANCELLED]: 'circle-check',
        [REFUND_VARIANT.ORDER_CHANGED_AFTER_TENDER]: 'circle-exclamation',
        [REFUND_VARIANT.TENDER_REMOVED]: 'circle-check',
        [REFUND_VARIANT.TOKEN_REMOVED]: 'circle-check',
      },
      HEADER_ICON: {
        [REFUND_VARIANT.ORDER_CANCELLED]: null,
        [REFUND_VARIANT.ORDER_CHANGED_AFTER_TENDER]: null,
        [REFUND_VARIANT.TENDER_REMOVED]: null,
        [REFUND_VARIANT.TOKEN_REMOVED]: 'trash',
      },
      ICON_COLORS: {
        [REFUND_VARIANT.ORDER_CANCELLED]: 'text-success',
        [REFUND_VARIANT.ORDER_CHANGED_AFTER_TENDER]: 'text-warning',
        [REFUND_VARIANT.TENDER_REMOVED]: 'text-success',
        [REFUND_VARIANT.TOKEN_REMOVED]: 'text-success',
      },
      SUMMARIES: {
        [REFUND_VARIANT.ORDER_CANCELLED]: this.$t('ebtPaymentRefundedSummary'),
        [REFUND_VARIANT.ORDER_CHANGED_AFTER_TENDER]: this.$t('ebtPaymentRefundedSummary'),
        [REFUND_VARIANT.TENDER_REMOVED]: this.$t('ebtRemovePaymentSummary'),
        [REFUND_VARIANT.TOKEN_REMOVED]: this.$t('ebtCardDeleted', {
          ending: this.ebtAuthList[0].lastFour,
        }),
      },
      POST_SUMMARIES: {
        [REFUND_VARIANT.ORDER_CANCELLED]: null,
        [REFUND_VARIANT.ORDER_CHANGED_AFTER_TENDER]: null,
        [REFUND_VARIANT.TENDER_REMOVED]: null,
        [REFUND_VARIANT.TOKEN_REMOVED]: this.$t('ebtCardDeletedSummary'),
      },
      ENDING_NOTES: {
        [REFUND_VARIANT.ORDER_CANCELLED]: this.$t('remainingTotalRefunded'),
        [REFUND_VARIANT.ORDER_CHANGED_AFTER_TENDER]: null,
        [REFUND_VARIANT.TENDER_REMOVED]: null,
        [REFUND_VARIANT.TOKEN_REMOVED]: null,
      },
    };
  },
  computed: {
    title(): String {
      return this.TITLES[this.variant];
    },
    iconColor(): Array<String> {
      return [this.ICON_COLORS[this.variant]];
    },
    summary(): String {
      return this.SUMMARIES[this.variant];
    },
    postSummary(): String {
      return this.POST_SUMMARIES[this.variant];
    },
    endingNote(): String {
      return this.ENDING_NOTES[this.variant];
    },
    headerIcon(): String {
      return this.HEADER_ICON[this.variant];
    },
    summaryIcon(): String {
      return this.SUMMARY_ICON[this.variant];
    },
  },
  methods: {
    ebtType(auth: EbtAuthInformation): String {
      return auth.isEbtSnap ? this.$t('ebtSnap') : this.$t('ebtCash');
    },
  },
});
</script>
