<template>
  <div
    v-if="activeOrderType && isNotAnEmployee"
    class="d-flex justify-content-center font-size-sm badge badge-primary badge-pill px-3 py-2 text-wrap"
  >
    {{ activeOrderType.name }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';

export default defineComponent({
  name: 'WCActiveOrderTypeBadge',
  mixins: [OrderTypesMixin, EmployeeMixin],
});
</script>
