<template>
  <div class="pt-3" v-if="(hasEbtTokenInWallet || showSpinner || displayBalances) && !isGuest">
    <div class="text-center" v-if="showSpinner">
      <WCSpinner />
    </div>
    <div v-else-if="hasEbtTokenInWallet">
      <button
        @click.stop="toggleDisplayBalances"
        class="btn btn-primary w-100"
        v-if="!displayBalances || !isEbtCacheValid"
      >
        {{ $t('checkEbtBalance') }}
      </button>
      <div class="d-flex align-items-center w-100" v-else-if="displayBalances">
        <button
          class="btn btn-primary wc-btn-opacity flex-grow-1 align-self-stretch wc-radius-left"
          :class="{ 'wc-mobile-font': isMobile, 'w-50': isSnapOrCashUnavaliable }"
          disabled
        >
          <span>{{ $t('ebtBalance') }}: </span>
          <span>
            <font-awesome-icon ref="info-icon" icon="circle-info" data-testid="infoIcon" />
            <WCToolTip target="info-icon" position="bottom"
              ><span data-testid="toolTipText">{{
                $filters.formatTimeStamp(getEbtBalanceTimeStamp)
              }}</span></WCToolTip
            >
          </span>
        </button>
        <button
          v-if="getEbtSnapCachedBalance != null"
          class="btn btn-outline-primary wc-btn-opacity flex-grow-1 align-self-stretch text-dark"
          :class="{
            'wc-mobile-font': isMobile,
            'wc-radius-none': getEbtCashCachedBalance != null,
            'wc-radius-right': getEbtCashCachedBalance == null,
            'w-50': isSnapOrCashUnavaliable,
          }"
          disabled
        >
          <span
            >{{ $t('ebtSnap') }}
            <span class="font-weight-bold">{{
              $filters.currency(getEbtSnapCachedBalance)
            }}</span></span
          >
        </button>
        <button
          v-if="getEbtCashCachedBalance != null"
          class="btn btn-outline-primary wc-btn-opacity flex-grow-1 align-self-stretch wc-radius-right text-dark"
          :class="{ 'wc-mobile-font': isMobile, 'w-50': isSnapOrCashUnavaliable }"
          disabled
        >
          <span
            >{{ $t('ebtCash') }}
            <span class="font-weight-bold">{{
              $filters.currency(getEbtCashCachedBalance)
            }}</span></span
          >
        </button>
        <div>
          <button
            class="unstyled-btn text-dark"
            :class="[isMobile ? 'wc-mobile-top-right' : 'wc-top-right']"
            @click.stop="closeBalance"
          >
            <font-awesome-icon icon="xmark" :size="iconSize" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';
import EbtBalanceCheckModal from '@/views/EbtBalanceCheck/EbtBalanceCheckModal.vue';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import WCToolTip from '@/components/WCToolTip/WCToolTip.vue';
import ModalService from '@/modules/modals/services/modal.service';
import ToastService from '@/modules/toasts/services/ToastService';
import { PlatformMixin } from '@/modules/platform';
import EbtBalanceCacheMixin from '@/modules/cart/mixins/EbtBalanceCacheMixin';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';

export default {
  name: 'WCCartDrawerEbtBalance',
  mixins: [EbtBalanceCacheMixin, PlatformMixin],
  components: { WCSpinner, WCToolTip },
  props: {
    walletEbtTokens: {
      type: Array,
    },
    ebtPaymentMethod: {
      type: Object,
    },
    loadingWallet: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loadingBalance: false as Boolean,
      displayBalances: false as Boolean,
    };
  },
  async mounted(): Promise<void> {
    this.displayBalances = this.isEbtCacheValid;
  },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest',
      getEbtBalanceTimeStamp: 'cart/getEbtBalanceTimeStamp',
    }),
    hasEbtTokenInWallet(): Boolean {
      return this.walletEbtTokens?.length > 0;
    },
    showSpinner(): Boolean {
      return this.loadingBalance || this.loadingWallet;
    },
    iconSize(): String {
      if (this.isMobile) {
        return 'xs';
      }
      return '1x';
    },
    isSnapOrCashUnavaliable() {
      return this.getEbtCashCachedBalance == null || this.getEbtSnapCachedBalance == null;
    },
  },
  methods: {
    closeBalance(): void {
      this.invalidateEbtBalanceCache();
      this.displayBalances = false;
    },
    toggleDisplayBalances(): void {
      this.displayBalances = true;
      if (!this.isEbtCacheValid) {
        this.displayBalanceCheckModal();
      }
    },
    async displayBalanceCheckModal(): Promise<void> {
      try {
        this.loadingBalance = true;
        await this.openBalanceCheckModal();
        this.loadingBalance = false;
      } catch (error) {
        this.loadingBalance = false;
        this.displayBalances = false;
        console.error(error);
        if (error !== 'Dismissed') {
          this.openBalanceErrorToast();
        }
      }
    },
    async openBalanceCheckModal(): Promise<void> {
      const token = this.walletEbtTokens && this.walletEbtTokens[0];
      await ModalService.open(EbtBalanceCheckModal, {
        paymentMethod: this.ebtPaymentMethod,
        token,
        hideBalanceDisplay: true,
      });
    },
    openBalanceErrorToast(): void {
      ToastService.open(WCSimpleToast, {
        props: {
          variant: 'danger',
          title: this.$t('error'),
          message: this.$t('balanceCheckFailure'),
        },
        timeout: 7000,
        actions: undefined,
        skipHash: false,
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-radius-none {
  border-radius: 0%;
  border-right: none;
}

.wc-radius-left {
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
}

.wc-radius-right {
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
}

.wc-top-right {
  position: absolute;
  float: right;
  top: 3.3rem;
  right: 1rem;
}

.wc-mobile-top-right {
  position: absolute;
  float: right;
  top: 3.25rem;
  right: 0.8rem;
}

.wc-mobile-font {
  font-size: small;
}

.wc-btn-opacity {
  opacity: 100% !important;
}
</style>
