import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wc-sync-error-toast__title mr-auto" }
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_WCToastHeader = _resolveComponent("WCToastHeader")!
  const _component_WCToastBody = _resolveComponent("WCToastBody")!
  const _component_WCToast = _resolveComponent("WCToast")!

  return (_openBlock(), _createBlock(_component_WCToast, { class: "wc-sync-error-toast float-right w-100" }, {
    default: _withCtx(() => [
      _createVNode(_component_WCToastHeader, null, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            class: "mr-2 text-danger",
            icon: "circle-exclamation"
          }),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _createElementVNode("strong", _hoisted_1, [
            _renderSlot(_ctx.$slots, "title", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.$t('error')), 1)
            ], true)
          ]),
          _cache[3] || (_cache[3] = _createTextVNode()),
          _createElementVNode("button", {
            type: "button",
            class: "ml-2 mb-1 close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dismiss())),
            "aria-label": _ctx.$t('close')
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("span", { "aria-hidden": "true" }, _toDisplayString('×'), -1)
          ]), 8, _hoisted_2)
        ]),
        _: 3
      }),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _createVNode(_component_WCToastBody, { class: "wc-sync-error-toast__body" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {
            close: _ctx.close,
            dismiss: _ctx.dismiss,
            startTimeout: _ctx.startTimeout
          }, () => [
            _createTextVNode(_toDisplayString($options.message), 1)
          ], true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}