<template>
  <div>
    <div class="w-100" v-if="numLoads === 0">
      <WCEbtCardEntry
        @cardCaptured="handleCardCaptured"
        @error="propagateError"
        :token="token"
        :submitText="$t(submitText)"
        :message="$t(message)"
      />

      <form
        :action="iframeSrc"
        method="post"
        :target="pinpadIframeId"
        :ref="pinpadIframeId"
        style="display:none"
      >
        <input type="text" id="AccuId" name="AccuId" v-model="accuId" />
        <input type="text" id="AccuReturnURL" name="AccuReturnURL" v-model="returnURL" />
        <input type="text" id="AccuLanguage" name="AccuLanguage" value="en-US" />
        <input type="submit" value="Submit" />
      </form>
    </div>

    <iframe
      :title="iframeTitle"
      v-if="numLoads < 2"
      :name="pinpadIframeId"
      :id="pinpadIframeId"
      :style="{ height: iframeSrc ? '40rem' : '0' }"
    ></iframe>
  </div>
</template>

<script lang="ts">
import WCEbtCardEntry from '@/components/WCEbtCardEntry/WCEbtCardEntry.vue';
import CardToken from '@/models/entities/card-token';
import { defineComponent } from 'vue';
import InitiateError from '@/models/entities/initiate-error';

export default defineComponent({
  emits: ['submit', 'error'],
  components: { WCEbtCardEntry },
  props: {
    token: {
      type: CardToken,
    },
    submitText: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: 'ebtPinDisclaimer',
    },
    pinpadIframeId: {
      type: String,
      default: 'pinpadFrame',
    },
  },
  data() {
    return {
      loading: false,
      account: {
        xKey: this.$configuration.cardknoxEbtIfieldsKey,
        xSoftwareName: 'webcart',
        xSoftwareVersion: '5.6.92',
      },
      multiUseToken: '',
      accuId: '',
      refNum: '',
      returnURL: '',
      card: {},
      iframeSrc: '',
      iframeTitle: 'Acculynk Pin Pad',
      numLoads: 0,
    };
  },
  mounted() {
    window.addEventListener('message', this.handleLoad);
  },
  unmounted() {
    window.removeEventListener('message', this.handleLoad);
  },
  methods: {
    async handleCardCaptured(payload) {
      const { card, accuId, returnUrl, refNum, iframeSrc, multiUseToken } = payload;
      this.card = card;
      this.accuId = accuId;
      this.returnUrl = returnUrl;
      this.iframeSrc = iframeSrc;
      this.refNum = refNum;
      this.multiUseToken = multiUseToken;
      // submit that form to render pin pad in iframe
      this.$nextTick(() => {
        this.$refs[this.pinpadIframeId].submit();
      });
      const frame = document.getElementById(this.pinpadIframeId);
      const vm = this;
      frame.addEventListener('load', function() {
        window.parent.postMessage(`loaded-${vm.pinpadIframeId}`, window.parent.origin);
      });
    },
    handleLoad(evt: MessageEvent) {
      if (evt.origin !== window.parent.origin) {
        return;
      }

      if (evt?.data === `loaded-${this.pinpadIframeId}`) {
        this.numLoads += 1;
        if (this.numLoads === 2) {
          // if the frame has loaded twice, then it has been submitted
          const frame = document.getElementById(this.pinpadIframeId);
          frame.style.visibility = 'hidden';
          this.$emit('submit', { refNum: this.refNum, card: this.card });
          this.resetFields();
        }
      }
    },
    propagateError(error: InitiateError) {
      this.$emit('error', error);
    },
    resetFields() {
      this.numLoads = 0;
      this.multiUseToken = '';
      this.refNum = '';
      this.accuId = '';
      this.$refs.pinpadIframeId?.reset();
    },
  },
});
</script>

<style scoped lang="scss">
iframe {
  border: 0;
  width: 100%;
  height: 4rem;
}
</style>
