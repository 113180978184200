<template>
  <div>
    <WCUserGreeter v-if="isCustomer || isEmployee" @logout="$emit('logout')" />
    <WCLoginMenu v-else />
  </div>
</template>
<script lang="ts">
import UserMixin from '@/modules/user/mixins/UserMixin';
import WCUserGreeter from '@/components/WCUserGreeter/WCUserGreeter.vue';
import WCLoginMenu from '@/components/WCLoginMenu/WCLoginMenu.vue';

export default {
  components: { WCLoginMenu, WCUserGreeter },
  mixins: [UserMixin],
  emits: ['logout'],
};
</script>
