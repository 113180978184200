import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "splide__arrows",
  "data-testid": "navigationArrows"
}
const _hoisted_2 = { class: "splide__arrow splide__arrow--prev splide-arrows" }
const _hoisted_3 = { class: "splide__arrow splide__arrow--next splide-arrows" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplideTrack = _resolveComponent("SplideTrack")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Splide = _resolveComponent("Splide")!
  const _component_WCCarousel = _resolveComponent("WCCarousel")!

  return (_openBlock(), _createBlock(_component_WCCarousel, { isSlider: true }, {
    default: _withCtx(() => [
      _createVNode(_component_Splide, {
        options: {
        perPage: _ctx.itemsPerPage,
        arrows: true,
        gap: '0.625rem',
      },
        "has-track": false,
        class: "container-fluid wc-splide m-auto rounded",
        role: "list"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SplideTrack, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
          }),
          _cache[3] || (_cache[3] = _createTextVNode()),
          (_ctx.showArrows)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("button", _hoisted_2, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "arrow-right-long",
                    size: "xl"
                  })
                ]),
                _cache[0] || (_cache[0] = _createTextVNode()),
                _cache[1] || (_cache[1] = _createElementVNode("ul", { class: "splide__pagination" }, null, -1)),
                _cache[2] || (_cache[2] = _createTextVNode()),
                _createElementVNode("button", _hoisted_3, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "arrow-right-long",
                    size: "xl"
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["options"])
    ]),
    _: 3
  }))
}