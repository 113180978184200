<template>
  <WCToast class="wc-sync-error-toast float-right w-100">
    <WCToastHeader>
      <font-awesome-icon class="mr-2 text-danger" icon="circle-exclamation" />
      <strong class="wc-sync-error-toast__title mr-auto">
        <slot name="title">{{ $t('error') }}</slot>
      </strong>
      <button type="button" class="ml-2 mb-1 close" @click="dismiss()" :aria-label="$t('close')">
        <span aria-hidden="true">{{ '&times;' }}</span>
      </button>
    </WCToastHeader>
    <WCToastBody class="wc-sync-error-toast__body">
      <slot v-bind:close="close" v-bind:dismiss="dismiss" v-bind:startTimeout="startTimeout">
        {{ message }}
      </slot>
    </WCToastBody>
  </WCToast>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { RESPONSE_CODE } from '@/constants/ApiConstants';
import ToastMixin from '../../mixins/ToastMixin';
import WCToast from '../WCToast/WCToast.vue';
import WCToastHeader from '../WCToastHeader/WCToastHeader.vue';
import WCToastBody from '../WCToastBody/WCToastBody.vue';

export default {
  name: 'WCSyncErrorToast',
  mixins: [ToastMixin],
  components: { FontAwesomeIcon, WCToast, WCToastHeader, WCToastBody },
  props: {
    errorCode: {
      type: Number,
      required: true,
    },
    errorMessage: {
      type: String,
      required: true,
    },
  },
  computed: {
    message() {
      if (this.errorCode === RESPONSE_CODE.CODE_CART_OUTDATED) {
        return this.$t('cartOutdatedMessage');
      }
      if (this.errorMessage) {
        return this.errorMessage;
      }
      return this.$t('error');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-sync-error-toast {
  border-left-width: 0.25rem;
  border-left-color: var(--danger, $danger);
}
</style>
