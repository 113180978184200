<template>
  <a
    v-if="webAdLink"
    :href="webAdLink"
    :target="isExternalLink ? '_blank' : ''"
    :rel="isExternalLink ? 'noopener noreferrer' : ''"
    :aria-label="webAd.label"
  >
    <div class="wc-web-ad wc-web-ad--fixed-size wc-webad-gradient"></div>
    <div
      class="wc-web-ad wc-web-ad--fixed-size position-relative user-select-none shadow-sm"
      :style="{
        'background-image': `url('${imageSrc}')`,
      }"
    />
    <font-awesome-icon
      v-if="!hasImg"
      class="wc-web-ad__fallback-img position-absolute text-muted font-size-5xl ml-n4 mt-n4"
      icon="camera"
      :title="$t('noProductImageAvailable')"
    />
  </a>

  <div v-else>
    <div class="wc-web-ad wc-web-ad--fixed-size wc-webad-gradient"></div>
    <button
      class="wc-web-ad wc-web-ad--fixed-size btn position-relative user-select-none shadow-sm"
      :style="{
        'background-image': `url('${imageSrc}')`,
      }"
      :aria-label="webAd.label"
    >
      <font-awesome-icon
        v-if="!hasImg"
        class="wc-web-ad__fallback-img position-absolute text-muted font-size-5xl ml-n4 mt-n4"
        icon="camera"
        :title="$t('noProductImageAvailable')"
      />
    </button>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import WebAd from '@/models/entities/web-ad';
import ElementSizeMixin from '@/mixins/ElementSizeMixin';

const regexUrlBeginsWithSlashSlashWithOptionalProtocol = /^(?:[a-z+]+:)?\/\//i;

export default defineComponent({
  props: {
    webAd: {
      type: Object as PropType<WebAd>,
    },
  },
  name: 'WCWebAd',
  mixins: [ElementSizeMixin],
  data() {
    return {
      WEB_AD_WITH_STYLE_NAME: '--web-ad-fixed-width',
    };
  },
  mounted() {
    this.addClientWidthToStyles(this.WEB_AD_WITH_STYLE_NAME);

    window.addEventListener('resize', () => {
      this.addClientWidthToStyles(this.WEB_AD_WITH_STYLE_NAME);
    });
  },
  updated() {
    this.addClientWidthToStyles(this.WEB_AD_WITH_STYLE_NAME);
  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
      this.addClientWidthToStyles(this.WEB_AD_WITH_STYLE_NAME);
    });
  },
  computed: {
    hasMessage() {
      return !!this.webAd.label;
    },
    hasImg() {
      return !!this.webAd.image;
    },
    imageSrc() {
      return this.hasImg ? `${this.$configuration.cdn}/api/img/${this.webAd.image}` : '';
    },
    webAdLink() {
      return this.webAd.link;
    },
    webAdLinkIsRelativeUrl(): boolean {
      return this.webAdLink?.includes(this.$configuration.siteUrl);
    },
    // Does the link for the Web Ad take the user to an external site?
    isExternalLink(): boolean {
      return (
        !this.webAdLinkIsRelativeUrl &&
        regexUrlBeginsWithSlashSlashWithOptionalProtocol.test(this.webAdLink)
      );
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-web-ad {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 0;
  border: none;
  padding: 0;
  height: 100%;
  width: 100%;

  &.wc-web-ad--fixed-size {
    width: 100%;
    height: calc(var(--web-ad-fixed-width) / 3.5); // 3.5:1 aspect ratio.
  }

  @include media-breakpoint-down(sm) {
    &.wc-web-ad--fixed-size {
      width: 100%;
      height: var(--web-ad-fixed-width);
    }

    &.wc-webad-gradient {
      background: linear-gradient(to bottom, transparent 85%, black 120%);
    }
  }

  .wc-web-ad__fallback-img {
    top: 50%;
    left: 50%;
  }
}

.wc-webad-gradient {
  position: absolute;
  z-index: 2;
  background: linear-gradient(to bottom, transparent 88%, black 120%);
}

#wc-ad-container {
  width: 100%;
}
</style>
