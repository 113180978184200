import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, withModifiers as _withModifiers, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "sr-only",
  "aria-live": "polite"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WCAddToListButton = _resolveComponent("WCAddToListButton")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_WCAddToCartQuantityAdjuster = _resolveComponent("WCAddToCartQuantityAdjuster")!
  const _component_WCOrderTypesAddToCartDropdown = _resolveComponent("WCOrderTypesAddToCartDropdown")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ dropdown: _ctx.showOrderTypeDropdown })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["wc-atc-btn wc-atc-btn--basic", { 'btn-group': _ctx.showOrderTypeDropdown }])
    }, [
      (_ctx.showOrderTypeDropdown && !_ctx.isScanAndGoMode)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args))),
            class: _normalizeClass(["btn btn-sm dropdown-toggle dropdown-toggle-split", 
          _ctx.linkButtonType
            ? 'wc-add-to-cart__dropdown-link text-primary'
            : 'wc-add-to-cart__dropdown btn-primary'
        ]),
            type: "button",
            id: "dropdownMenuButton",
            "data-bs-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          }, null, 2))
        : _createCommentVNode("", true),
      _cache[7] || (_cache[7] = _createTextVNode()),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('quantity')) + " " + _toDisplayString(_ctx.getDisplayQuantityInCart(_ctx.item)), 1),
      _cache[8] || (_cache[8] = _createTextVNode()),
      (_ctx.listOnly)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!_ctx.isMto)
              ? (_openBlock(), _createBlock(_component_WCAddToListButton, {
                  key: 0,
                  item: _ctx.item,
                  describedBy: _ctx.item.id,
                  btnVarient: _ctx.addToListInferredVariant
                }, null, 8, ["item", "describedBy", "btnVarient"]))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: _normalizeClass(
            _ctx.addToListVariantIsMobileCard
              ? 'btn text-primary rounded add-to-list--btn-mobile'
              : 'mobile-btn btn btn-primary btn-block'
          ),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.customizeItemToAddToList())),
                  ref: "customizeBtnRef"
                }, [
                  (!_ctx.addToListVariantIsMobileCard)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('customizeAndAddToList')), 1))
                    : _createCommentVNode("", true),
                  _cache[6] || (_cache[6] = _createTextVNode()),
                  _createVNode(_component_font_awesome_icon, {
                    class: "ml-2",
                    icon: "list-check"
                  })
                ], 2))
          ], 64))
        : (_ctx.notAvailable)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              id: 'batcb_nabtn_' + _ctx.item.id,
              class: "mobile-btn btn btn-primary btn-block",
              disabled: ""
            }, _toDisplayString(_ctx.$t('notAvailable')), 9, _hoisted_3))
          : (_ctx.outOfStock)
            ? (_openBlock(), _createElementBlock("button", {
                key: 3,
                id: 'batcb_oosbtn_' + _ctx.item.id,
                class: "mobile-btn btn btn-primary btn-block",
                disabled: ""
              }, _toDisplayString(_ctx.$t('outOfStock')), 9, _hoisted_4))
            : (_ctx.inStoreOnly)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 4,
                  id: 'batcb_isobtn_' + _ctx.item.id,
                  class: "mobile-btn btn btn-primary btn-block",
                  disabled: ""
                }, _toDisplayString(_ctx.$t('inStoreOnly')), 9, _hoisted_5))
              : (_ctx.notEligibleForCart)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 5,
                    class: _normalizeClass(["mobile-btn btn btn-block", _ctx.linkButtonType ? 'wc-add-to-cart-link__button text-primary p-0' : 'btn-primary']),
                    onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)), ["stop"]))
                  }, _toDisplayString(_ctx.$t('chooseCart')), 3))
                : (_ctx.showAddToCartOption)
                  ? (_openBlock(), _createBlock(_component_WCAddToCartQuantityAdjuster, {
                      key: 6,
                      item: _ctx.item,
                      linkButtonType: _ctx.linkButtonType,
                      describedBy: _ctx.describedBy,
                      showMobileCustomize: _ctx.showMobileCustomize,
                      showOrderTypeDropdown: _ctx.showOrderTypeDropdown,
                      variation: _ctx.variation,
                      isListItem: _ctx.isListItem,
                      priceEmbeddedLineItem: _ctx.priceEmbeddedLineItem,
                      orderType: _ctx.orderType,
                      entryMethod: _ctx.entryMethod,
                      class: "btn-block",
                      onAddToCart: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('addToCart'))),
                      "data-testid": "quantityAdjusterButton"
                    }, null, 8, ["item", "linkButtonType", "describedBy", "showMobileCustomize", "showOrderTypeDropdown", "variation", "isListItem", "priceEmbeddedLineItem", "orderType", "entryMethod"]))
                  : _createCommentVNode("", true)
    ], 2),
    _cache[9] || (_cache[9] = _createTextVNode()),
    (!_ctx.isScanAndGoMode)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["dropdown-menu wc-cart-dropdown-menu", { show: _ctx.showDropdown }]),
          onKeydown: _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.hideDropdown && _ctx.hideDropdown(...args)), ["esc"])),
          "aria-labelledby": "dropdownMenuButton"
        }, [
          _createVNode(_component_WCOrderTypesAddToCartDropdown, {
            item: _ctx.item,
            isListItem: _ctx.isListItem,
            variation: _ctx.variation,
            showMobileCustomize: _ctx.showMobileCustomize,
            orderTypes: _ctx.eligibleInactiveOrderTypes,
            entryMethod: _ctx.entryMethod,
            onAddToCart: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('addToCart')))
          }, null, 8, ["item", "isListItem", "variation", "showMobileCustomize", "orderTypes", "entryMethod"])
        ], 34))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_click_outside, _ctx.hideDropdown]
  ])
}