import { createI18n } from 'vue-i18n';
import { store } from '@/store';
import { isLocaleSupported, getBrowserLocale } from '../utils/locales';
import Locales, { ENGLISH } from '../locales';

function loadLocaleMessages() {
  const messages = {};
  Locales.forEach(locale => {
    // eslint-disable-next-line global-require, import/no-dynamic-require
    messages[locale.code] = require(`../locales/${locale.code}.json`).default;
  });
  return messages;
}

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });
  const storedLocale = localStorage.getItem('locale');
  const advSetLanguage = store.getters.getConfiguration.i18nLanguage
    ? store.getters.getConfiguration.i18nLanguage[0]
    : '';

  if (storedLocale) {
    return storedLocale;
  }

  if (isLocaleSupported(browserLocale)) {
    return browserLocale;
  }

  if (advSetLanguage && advSetLanguage.length) {
    return advSetLanguage;
  }
  return process.env.VUE_APP_I18N_LOCALE || ENGLISH.code;
}

export default function geti18nFromConfig() {
  return createI18n({
    legacy: false,
    locale: getStartingLocale(),
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || ENGLISH.code,
    messages: loadLocaleMessages(),
  });
}
