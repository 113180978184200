import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 3 }
const _hoisted_2 = ["disabled", "aria-label", "aria-describedby"]
const _hoisted_3 = { class: "wc-atc-btn__quantity-label px-2" }
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = ["disabled", "aria-label", "aria-describedby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WCCustomizeButton = _resolveComponent("WCCustomizeButton")!
  const _component_WCCustomizeButtonMobile = _resolveComponent("WCCustomizeButtonMobile")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return ((_ctx.mtoItemNeedsCustomization || _ctx.showRandomWeight) && !_ctx.showMobileCustomize && !_ctx.isListItem)
    ? (_openBlock(), _createBlock(_component_WCCustomizeButton, {
        key: 0,
        class: "btn btn-primary btn-block w-100",
        item: _ctx.item,
        orderType: _ctx.orderType,
        isRandomWeightedItem: _ctx.showRandomWeight
      }, null, 8, ["item", "orderType", "isRandomWeightedItem"]))
    : ((_ctx.mtoItemNeedsCustomization || _ctx.showRandomWeight) && !_ctx.isListItem)
      ? (_openBlock(), _createBlock(_component_WCCustomizeButtonMobile, {
          key: 1,
          class: "btn btn-block w-100",
          item: _ctx.item,
          orderType: _ctx.orderType,
          isRandomWeightedItem: _ctx.showRandomWeight
        }, null, 8, ["item", "orderType", "isRandomWeightedItem"]))
      : (_ctx.getQuantityInCart(_ctx.item, _ctx.itemVariation).isZero() || _ctx.isListItem)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: _normalizeClass(["btn w-100 d-flex", 
      _ctx.linkButtonType
        ? ['wc-add-to-cart-link__button', 'text-primary']
        : ['wc-add-to-cart__button', 'btn-primary']
    ]),
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onClickAddToCart(_ctx.item)), ["stop"]))
          }, [
            _cache[3] || (_cache[3] = _createElementVNode("span", null, null, -1)),
            _cache[4] || (_cache[4] = _createTextVNode()),
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.linkButtonType && 'order-2')
            }, _toDisplayString(_ctx.$t('addToCart')), 3),
            _cache[5] || (_cache[5] = _createTextVNode()),
            _createVNode(_component_font_awesome_icon, {
              class: _normalizeClass(_ctx.linkButtonType && 'order-1 mt-1 mr-1'),
              icon: "cart-shopping",
              size: "sm"
            }, null, 8, ["class"])
          ], 2))
        : (
      !_ctx.getQuantityInCart(_ctx.item, _ctx.itemVariation).isZero() && !_ctx.isListItem && !_ctx.priceEmbeddedLineItem
    )
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_ctx.showQuantityAdjuster)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(
        _ctx.$configuration.orderTypesEnabled &&
        _ctx.describedBy !== 'WC Add To Order Type Cart Toast' &&
        _ctx.showOrderTypeDropdown
          ? ['btn w-100 wc-atc-btn-adjuster-wrapper__border border-primary text-primary bg-white']
          : ['btn w-100 wc-atc-btn__adjuster-wrapper  border-primary text-primary bg-white']
      )
                  }, [
                    _createElementVNode("button", {
                      class: _normalizeClass(["btn py-0 px-1 border-0", 
          _ctx.isNextQuantityDecrementInCartValid(_ctx.item, _ctx.itemVariation) ? 'text-primary' : 'text-muted'
        ]),
                      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onClickDecrement(_ctx.item, _ctx.itemVariation)), ["stop"])),
                      disabled: !_ctx.isNextQuantityDecrementInCartValid(_ctx.item, _ctx.itemVariation),
                      "aria-label": _ctx.$t('decreaseQuantity'),
                      "aria-describedby": _ctx.describedBy
                    }, [
                      (_ctx.getNextQuantityDecrementInCart(_ctx.item).isZero())
                        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                            key: 0,
                            icon: "trash",
                            size: "sm"
                          }))
                        : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                            key: 1,
                            icon: "minus",
                            size: "sm"
                          }))
                    ], 10, _hoisted_2),
                    _cache[6] || (_cache[6] = _createTextVNode()),
                    _createElementVNode("span", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('quantity')), 1),
                      _createTextVNode(_toDisplayString(_ctx.getDisplayQuantityInCart(_ctx.item)), 1)
                    ]),
                    _cache[7] || (_cache[7] = _createTextVNode()),
                    _createElementVNode("button", {
                      class: _normalizeClass(["btn py-0 px-1 border-0", 
          _ctx.isNextQuantityIncrementInCartValid(_ctx.item, _ctx.itemVariation) ? 'text-primary' : 'text-muted'
        ]),
                      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onClickAddToCart(_ctx.item, _ctx.variation)), ["stop"])),
                      disabled: !_ctx.isNextQuantityIncrementInCartValid(_ctx.item, _ctx.itemVariation),
                      "aria-label": _ctx.$t('increaseQuantity'),
                      ref: "incrementButtonRef",
                      "aria-describedby": _ctx.describedBy
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "plus",
                        size: "sm"
                      })
                    ], 10, _hoisted_5)
                  ], 2))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
}