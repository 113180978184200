<template>
  <div
    v-if="isRibbonVisible"
    class="wc-coupon-ribbon py-1 px-2 font-size-xs rounded"
    :class="variant"
  >
    {{ textContent }}
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import { PROMO_RIBBON_TYPE, RIBBON_CONFIG } from '../../constants/PromoRibbonConstants';
import { COUPON_STATUS_FILTERS } from '../../constants/CouponConstants';

export default defineComponent({
  props: {
    offer: {
      type: Object,
      required: true,
    },
    couponFilter: {
      type: String,
      default: 'available',
    },
    isClipped: {
      type: Boolean,
    },
    isRedeemed: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      clippedCoupons: 'coupons/getClippedCoupons',
      redeemedCoupons: 'coupons/getRedeemedCoupons',
    }),
    ribbonConfig() {
      return RIBBON_CONFIG[PROMO_RIBBON_TYPE.DIGITAL_COUPON_RIBBON];
    },
    isRibbonVisible() {
      return this.ribbonConfig.canDisplay(this.offer);
    },
    textContent() {
      if (this.couponFilter === COUPON_STATUS_FILTERS.CLIPPED) {
        return this.ribbonConfig.getClippedTextContext(this.usagesLeft);
      }
      if (this.couponFilter === COUPON_STATUS_FILTERS.REDEEMED) {
        return this.ribbonConfig.getRedeemedTextContext(this.usagesRedeemed);
      }
      if (this.isClipped) {
        return this.ribbonConfig.getClippedTextContext(this.usagesLeft);
      }
      if (this.isRedeemed) {
        return this.ribbonConfig.getRedeemedTextContext(this.usagesRedeemed);
      }
      return this.ribbonConfig.getAvaliableTextContext(this.offer?.redeemLimit);
    },
    usagesLeft() {
      return this.clippedCoupons?.filter(i => i === this.offer?.id)?.length;
    },
    usagesRedeemed() {
      return parseInt(this.offer?.redeemLimit, 10) - this.usagesLeft;
    },
    variant() {
      return this.ribbonConfig.variant;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-coupon-ribbon {
  display: inline-block;

  &.info {
    background-color: var(--info, $info);
    color: var(--info-contrast, get-color-contrast('info'));
  }
  &.secondary {
    background-color: var(--secondary, $secondary);
    color: var(--secondary-contrast, get-color-contrast('secondary'));
  }
}
</style>
