import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-center align-items-baseline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return ($options.isMultiStore && _ctx.isNotAnEmployee)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_font_awesome_icon, {
          class: "mr-1",
          icon: "location-dot",
          size: "sm"
        }),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createVNode(_component_router_link, {
          to: "/select-store",
          class: "text-body underline-link-from-center text-break mr-1",
          "data-testid": "storeName"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString($options.storeName), 1)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}