import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-row p-3 justify-content-between align-items-center" }
const _hoisted_2 = { class: "d-flex flex-row align-items-center" }
const _hoisted_3 = { class: "d-flex flex-column" }
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = {
  key: 0,
  class: "text-danger font-size-xs mr-1",
  "data-testid": "coupon-not-available-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WCImage = _resolveComponent("WCImage")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_WCCouponClipButton = _resolveComponent("WCCouponClipButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_WCImage, {
        imageSrc: _ctx.coupon.imageUrl || '',
        imageAlt: _ctx.coupon.name,
        imageClass: "w-25 h-25 p-1 wc-coupon-image",
        defaultClass: "w-25 h-25 p-1 wc-coupon-image",
        defaultTitle: _ctx.coupon.name
      }, null, 8, ["imageSrc", "imageAlt", "defaultTitle"]),
      _cache[3] || (_cache[3] = _createTextVNode()),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          "aria-label": `Coupon ${_ctx.coupon.name}`,
          "data-testid": "couponTitle",
          class: "btn link-dark underline-link-from-center wc-coupon-name font-size-xs",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleModalSwap && _ctx.handleModalSwap(...args)))
        }, _toDisplayString(_ctx.couponTitle), 9, _hoisted_4),
        _cache[2] || (_cache[2] = _createTextVNode()),
        (!_ctx.couponDisplayable(_ctx.coupon))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_font_awesome_icon, {
                class: "mr-1 text-danger",
                icon: "circle-exclamation",
                size: "lg"
              }),
              _cache[1] || (_cache[1] = _createTextVNode()),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('couponNotAvailable')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _cache[4] || (_cache[4] = _createTextVNode()),
    _createVNode(_component_WCCouponClipButton, {
      class: "wc-coupon-card__bottom-button wc-coupon-card__bottom-button-detail",
      couponId: _ctx.coupon.id,
      availableLabel: _ctx.$t('clipCoupon'),
      clippedLabel: _ctx.$t('clippedCoupon'),
      redeemedLabel: _ctx.$t('couponRedeemed'),
      disabled: false,
      isClipped: _ctx.evaluateClipStatus,
      isRedeemed: _ctx.evaluateRedeemedStatus,
      handleClip: _ctx.handleClip
    }, null, 8, ["couponId", "availableLabel", "clippedLabel", "redeemedLabel", "isClipped", "isRedeemed", "handleClip"])
  ]))
}