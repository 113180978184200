<template>
  <WCCarousel :isSlider="true">
    <Splide
      :options="{
        perPage: itemsPerPage,
        arrows: true,
        gap: '0.625rem',
      }"
      :has-track="false"
      class="container-fluid wc-splide m-auto rounded"
      role="list"
    >
      <SplideTrack>
        <slot></slot>
      </SplideTrack>

      <div v-if="showArrows" class="splide__arrows" data-testid="navigationArrows">
        <button class="splide__arrow splide__arrow--prev splide-arrows">
          <font-awesome-icon icon="arrow-right-long" size="xl" />
        </button>
        <ul class="splide__pagination"></ul>
        <button class="splide__arrow splide__arrow--next splide-arrows">
          <font-awesome-icon icon="arrow-right-long" size="xl" />
        </button>
      </div>
    </Splide>
  </WCCarousel>
</template>

<script lang="ts">
import WCCarousel from '@/components/WCCarousel/WCCarousel.vue';
import { Splide, SplideTrack } from '@splidejs/vue-splide';
import { PlatformMixin } from '@/modules/platform';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    WCCarousel,
    Splide,
    SplideTrack,
  },
  props: {
    objectList: {
      type: Array,
      default: undefined,
    },
    itemsPerPage: {
      type: Number,
      default: 1,
    },
  },
  mixins: [PlatformMixin],
  data() {
    return {
      showArrows: true,
    };
  },
  mounted() {
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.setArrowVisibility();
    },
    setArrowVisibility() {
      this.showArrows = this.itemsPerPage < this.objectList.length;
    },
  },
  watch: {
    itemsPerPage() {
      this.setArrowVisibility();
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-splide {
  padding-left: 0;
  padding-right: 0;
}

.splide__arrows {
  color: var(--primary, $primary);
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

:deep(.splide__arrows) {
  font-size: 1.5rem !important;
  padding-top: 0.5rem;
}

:deep(.splide__pagination__page) {
  gap: rem(20px);
  width: 0.625rem;
  height: 0.625rem;
}

.splide__arrow--prev {
  position: relative;
  padding-top: 2rem;
  width: fit-content;
  padding-right: 2rem;
}

.splide__arrow--next {
  position: relative;
  padding-top: 2rem;
  width: fit-content;
  padding-left: 2rem;
}

.splide__pagination {
  position: relative;
  width: fit-content;
  font-size: 1.5rem !important;
}
</style>
