import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "target", "rel", "aria-label"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.webAdLink)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.webAdLink,
        target: _ctx.isExternalLink ? '_blank' : '',
        rel: _ctx.isExternalLink ? 'noopener noreferrer' : '',
        "aria-label": _ctx.webAd.label
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "wc-web-ad wc-web-ad--fixed-size wc-webad-gradient" }, null, -1)),
        _cache[1] || (_cache[1] = _createTextVNode()),
        _createElementVNode("div", {
          class: "wc-web-ad wc-web-ad--fixed-size position-relative user-select-none shadow-sm",
          style: _normalizeStyle({
        'background-image': `url('${_ctx.imageSrc}')`,
      })
        }, null, 4),
        _cache[2] || (_cache[2] = _createTextVNode()),
        (!_ctx.hasImg)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              class: "wc-web-ad__fallback-img position-absolute text-muted font-size-5xl ml-n4 mt-n4",
              icon: "camera",
              title: _ctx.$t('noProductImageAvailable')
            }, null, 8, ["title"]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "wc-web-ad wc-web-ad--fixed-size wc-webad-gradient" }, null, -1)),
        _cache[4] || (_cache[4] = _createTextVNode()),
        _createElementVNode("button", {
          class: "wc-web-ad wc-web-ad--fixed-size btn position-relative user-select-none shadow-sm",
          style: _normalizeStyle({
        'background-image': `url('${_ctx.imageSrc}')`,
      }),
          "aria-label": _ctx.webAd.label
        }, [
          (!_ctx.hasImg)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                class: "wc-web-ad__fallback-img position-absolute text-muted font-size-5xl ml-n4 mt-n4",
                icon: "camera",
                title: _ctx.$t('noProductImageAvailable')
              }, null, 8, ["title"]))
            : _createCommentVNode("", true)
        ], 12, _hoisted_3)
      ]))
}