<template>
  <div
    v-if="isRibbonVisible"
    class="wc-coupon-ribbon py-1 px-2 font-size-xs rounded"
    data-testid="coupon-not-available-ribbon"
    :class="variant"
  >
    <font-awesome-icon class="mr-1 text-black" icon="circle-exclamation" />
    <span>{{ textContent }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { PROMO_RIBBON_TYPE, RIBBON_CONFIG } from '../../constants/PromoRibbonConstants';

export default defineComponent({
  components: { FontAwesomeIcon },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ribbonConfig() {
      return RIBBON_CONFIG[PROMO_RIBBON_TYPE.COUPON_NOT_AVAILABLE_RIBBON];
    },
    isRibbonVisible() {
      return this.ribbonConfig.canDisplay(this.offer);
    },
    textContent() {
      return this.$t('notAvailableAtThisStore');
    },
    variant() {
      return this.ribbonConfig.variant;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-coupon-ribbon {
  display: inline-block;

  &.danger {
    background-color: var(--danger, $danger);
    color: black;
  }
}
</style>
