import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-items-center"
}
const _hoisted_2 = { class: "d-flex flex-column justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return ($options.isMultiStore && _ctx.isNotAnEmployee)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          class: "d-flex location rounded-circle align-items-center justify-content-center bg-secondary mr-2",
          to: "/select-store"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "location-dot",
              size: "lg"
            })
          ]),
          _: 1
        }),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, {
            to: "/select-store",
            class: "text-body font-size-sm lh-1 underline-link-from-center fit-content text-break",
            "data-testid": "storeName"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($options.storeName), 1)
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}