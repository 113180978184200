import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["aria-expanded"]
const _hoisted_2 = { class: "d-flex login-icon rounded-circle align-items-center justify-content-center bg-secondary mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown", { show: $data.show }]),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => ($options.hide && $options.hide(...args)), ["esc"]))
  }, [
    _createElementVNode("a", {
      id: "login-dropdown",
      class: "d-flex align-items-center dropdown-toggle text-body",
      href: "#",
      role: "button",
      "aria-haspopup": "true",
      "aria-expanded": $data.show,
      "data-toggle": "dropdown",
      "data-testid": "login-dropdown",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($options.toggle && $options.toggle(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_font_awesome_icon, {
          icon: "right-to-bracket",
          size: "lg"
        })
      ]),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('signIn')), 1)
    ], 8, _hoisted_1),
    _cache[3] || (_cache[3] = _createTextVNode()),
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown-menu", { show: $data.show }]),
      role: "menu",
      "aria-labelledby": "login-dropdown"
    }, [
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: "/login",
        "data-testid": "login-link"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('signIn')), 1)
        ]),
        _: 1
      }),
      _cache[2] || (_cache[2] = _createTextVNode()),
      (_ctx.$configuration.allowCustomerAdd)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            class: "dropdown-item",
            to: "/register",
            "data-testid": "register-link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('register')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], 2)
  ], 34)), [
    [_directive_click_outside, $options.hide]
  ])
}