<template>
  <div class="font-size-sm text-primary">
    <div class="font-weight-bold">
      {{ $t('currentSelection') }}
    </div>
    <div>{{ this.weightIncrement }} {{ $t('each') }}</div>
    <div>{{ this.totalOrderedWeight }} {{ this.weightUnit }} {{ $t('total') }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CartControls from '@/modules/cart/mixins/CartControls/CartControls';

export default defineComponent({
  mixins: [CartControls],
  props: {
    lineItem: {
      type: Object,
    },
  },
  computed: {
    weightUnit() {
      return this.lineItem.item.weightProfile.abbrv;
    },
    weightIncrement() {
      return this.getDisplayQuantityInCart(this.lineItem.item, this.lineItem.variation);
    },
    totalOrderedWeight() {
      return this.getTotalBaseQuantityInCart(this.lineItem.item, this.lineItem.variation);
    },
  },
});
</script>
