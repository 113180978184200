<template>
  <WCModal position="center" @dismiss="dismiss(MODAL_EVENTS.DISMISS)">
    <WCModalHeader class="align-items-baseline">
      <h1 class="font-size-lg">
        {{ $t('resetIndex') }}
      </h1>
      <button
        class="btn btn-link underline-link-from-center"
        data-testid="cancel-button"
        @click="close(MODAL_EVENTS.HEADER_CANCEL)"
      >
        {{ $t('cancel') }}
      </button>
    </WCModalHeader>

    <WCModalBody>
      <div class="d-flex flex-column">
        <span>{{ $t('resetIndexWarning') }}</span>

        <span class="font-italic py-3"> {{ $t('resetIndexNote') }}</span>

        <span class="font-weight-bold">{{ $t('resetIndexConfirmation') }}</span>
      </div>
    </WCModalBody>

    <WCModalFooter>
      <button
        @click="close(MODAL_EVENTS.CONFIRM)"
        class="btn btn-outline-primary"
        data-testid="confirm-reset"
      >
        {{ $t('yesContinue') }}
      </button>

      <button
        @click="dismiss(MODAL_EVENTS.CANCEL)"
        class="btn btn-outline-dark font-size-sm"
        data-testid="cancel-reset"
      >
        {{ $t('noCancel') }}
      </button>
    </WCModalFooter>
  </WCModal>
</template>

<script lang="ts">
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import { MODAL_EVENTS } from '@/constants/EventConstants';

export default {
  mixins: [ModalMixin],
  data() {
    return {
      MODAL_EVENTS,
    };
  },
  components: { WCModal, WCModalHeader, WCModalBody, WCModalFooter },
};
</script>
