<template>
  <WCCustomizeButton
    class="btn btn-primary btn-block w-100"
    v-if="(mtoItemNeedsCustomization || showRandomWeight) && !showMobileCustomize && !isListItem"
    :item="item"
    :orderType="orderType"
    :isRandomWeightedItem="showRandomWeight"
  />
  <WCCustomizeButtonMobile
    v-else-if="(mtoItemNeedsCustomization || showRandomWeight) && !isListItem"
    class="btn btn-block w-100"
    :item="item"
    :orderType="orderType"
    :isRandomWeightedItem="showRandomWeight"
  />
  <!-- If items has modifiers or linked items, customize button will be displayed -->

  <!-- Standard Button -->
  <button
    v-else-if="getQuantityInCart(item, itemVariation).isZero() || isListItem"
    class="btn w-100 d-flex"
    :class="
      linkButtonType
        ? ['wc-add-to-cart-link__button', 'text-primary']
        : ['wc-add-to-cart__button', 'btn-primary']
    "
    @click.stop="onClickAddToCart(item)"
  >
    <span><!-- Invisible element for flexing center and right --></span>

    <span :class="linkButtonType && 'order-2'">
      {{ $t('addToCart') }}
    </span>
    <font-awesome-icon
      :class="linkButtonType && 'order-1 mt-1 mr-1'"
      icon="cart-shopping"
      size="sm"
    />
  </button>

  <!-- Quantity Adjuster -->
  <div
    v-else-if="
      !getQuantityInCart(item, itemVariation).isZero() && !isListItem && !priceEmbeddedLineItem
    "
  >
    <div
      v-if="showQuantityAdjuster"
      :class="
        $configuration.orderTypesEnabled &&
        describedBy !== 'WC Add To Order Type Cart Toast' &&
        showOrderTypeDropdown
          ? ['btn w-100 wc-atc-btn-adjuster-wrapper__border border-primary text-primary bg-white']
          : ['btn w-100 wc-atc-btn__adjuster-wrapper  border-primary text-primary bg-white']
      "
    >
      <button
        class="btn py-0 px-1 border-0"
        :class="
          isNextQuantityDecrementInCartValid(item, itemVariation) ? 'text-primary' : 'text-muted'
        "
        @click.stop="onClickDecrement(item, itemVariation)"
        :disabled="!isNextQuantityDecrementInCartValid(item, itemVariation)"
        :aria-label="$t('decreaseQuantity')"
        :aria-describedby="describedBy"
      >
        <font-awesome-icon
          v-if="getNextQuantityDecrementInCart(item).isZero()"
          icon="trash"
          size="sm"
        />
        <font-awesome-icon v-else icon="minus" size="sm" />
      </button>
      <span class="wc-atc-btn__quantity-label px-2"
        ><!-- Screen reader only visible label for quantity -->
        <span class="sr-only"> {{ $t('quantity') }}</span
        >{{ getDisplayQuantityInCart(item) }}</span
      >
      <button
        class="btn py-0 px-1 border-0"
        :class="
          isNextQuantityIncrementInCartValid(item, itemVariation) ? 'text-primary' : 'text-muted'
        "
        @click.stop="onClickAddToCart(item, variation)"
        :disabled="!isNextQuantityIncrementInCartValid(item, itemVariation)"
        :aria-label="$t('increaseQuantity')"
        ref="incrementButtonRef"
        :aria-describedby="describedBy"
      >
        <font-awesome-icon icon="plus" size="sm" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters, mapActions } from 'vuex';
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import ExitScanAndGo from '@/views/ScanProduct/ExitScanAndGo/ExitScanAndGo.vue';
import ModalService from '@/modules/modals/services/modal.service';
import WCCustomizeButton from '@/modules/cart/components/WCCustomizeButton/WCCustomizeButton.vue';
import WCCustomizeButtonMobile from '@/modules/cart/components/WCCustomizeButton/WCCustomizeButtonMobile.vue';
import { defineComponent, PropType } from 'vue';
import { ENTRY_METHOD, EntryMethod } from '@/models/entities/cart/entry-method';

export default defineComponent({
  name: 'WCAddToCartQuantityAdjuster',
  emits: ['decrement', 'addToCart'],
  mixins: [CartControlsMixin, OrderTypesMixin],
  props: {
    linkButtonType: {
      type: Boolean,
      default: false,
    },
    describedBy: {
      type: String,
    },
    showMobileCustomize: {
      type: Boolean,
    },
    priceEmbeddedLineItem: {
      type: Object,
      required: false,
    },
    variation: {},
    isListItem: {
      type: Boolean,
      default: false,
    },
    showOrderTypeDropdown: {
      type: Boolean,
      default: false,
    },
    showStoreSelectModal: {
      type: Boolean,
      default: true,
    },
    entryMethod: {
      type: String as PropType<EntryMethod>,
      required: false,
      default: ENTRY_METHOD.DEFAULT,
    },
  },
  components: {
    WCCustomizeButton,
    WCCustomizeButtonMobile,
  },
  computed: {
    ...mapGetters({
      isInStore: 'cart/getInStore',
    }),
    mtoItemNeedsCustomization() {
      return (
        (this.item.hasModifiers || this.item.hasLinkedItems) &&
        !this.variation &&
        !this.priceEmbeddedLineItem
      );
    },
    itemVariation() {
      return this.variation && this.variation.id ? this.variation.id : this.variation;
    },
    showQuantityAdjuster() {
      return !(this.isSoldByRandomWeight(this.item) && this.isCustomerModeScan);
    },
    showRandomWeight() {
      return (
        this.isSoldByRandomWeightWithDualAdjusters(this.item) && !this.mtoItemNeedsCustomization
      );
    },
  },
  methods: {
    ...mapActions({
      setInStore: 'cart/setInStore',
    }),
    async onClickAddToCart(item) {
      await this.setInStore(this.isCustomerModeScan);

      if (this.isCustomerModeScan || (!this.isInStore && !this.isCustomerModeScan)) {
        await this.increment(
          item,
          this.variation,
          this.priceEmbeddedLineItem,
          this.showStoreSelectModal,
          this.entryMethod,
        );
        this.$emit('addToCart');

        if (this.isInStore && !this.isCustomerModeScan) {
          try {
            await ModalService.open(ExitScanAndGo);
          } catch (error) {
            console.error(error);
          }
        }
      }
      this.$nextTick(() => {
        this.$refs?.incrementButtonRef?.focus();
      });
    },
    onClickDecrement(item, variation) {
      this.$emit('decrement');
      this.decrement(item, variation);
    },
  },
});
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-add-to-cart__button {
  color: var(--primary-contrast, get-color-contrast('primary'));
  background-color: var(--primary, $primary);
  border-color: var(--primary, $primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wc-add-to-cart-link__button {
  min-width: 6.5rem;
  color: var(--primary, $primary);
  margin: 0.375rem 0.5rem;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

// Styling should closely match the Bootstrap outline button
.wc-atc-btn__adjuster-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  background-color: transparent;
  border: 1px solid;
  padding: $pad-3 * 0.375 $pad-3 * 0.75;
  font-size: $font-size-base;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.wc-atc-btn-adjuster-wrapper__border {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  background-color: transparent;
  border-top: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-left: none;
  padding: $pad-3 * 0.375 $pad-3 * 0.75;
  font-size: $font-size-base;
  line-height: 1.5;
  border-radius: 0 0.25rem 0.25rem 0;
}
</style>
