<template>
  <div
    v-if="isMultiStore && isNotAnEmployee"
    class="d-flex justify-content-center align-items-baseline"
  >
    <font-awesome-icon class="mr-1" icon="location-dot" size="sm" />
    <router-link
      to="/select-store"
      class="text-body underline-link-from-center text-break mr-1"
      data-testid="storeName"
    >
      <span>{{ storeName }}</span>
    </router-link>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapGetters } from 'vuex';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';

export default {
  components: [FontAwesomeIcon],
  mixins: [EmployeeMixin],
  computed: {
    ...mapGetters({ getUser: 'user/getUser' }),
    storeName() {
      return this.$configuration.store.name;
    },
    isMultiStore() {
      return this.$configuration.multistore;
    },
  },
};
</script>
