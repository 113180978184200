/**
 * Filter for employee actions in mobile and desktop
 */
import axios from 'axios';
import { mapGetters } from 'vuex';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import ModalService from '@/modules/modals/services/modal.service';
import { MODAL_EVENTS } from '@/constants/EventConstants';
import WCResetIndexModal from '@/components/WCResetIndexModal/WCResetIndexModal.vue';

export default {
  computed: {
    ...mapGetters({
      isCustomer: 'user/isCustomer',
      isEmployee: 'user/isEmployee',
      user: 'user/getUser',
    }),
    downloadLogPath() {
      return 'api/employee/logs.zip';
    },
    /**
     * Property to check whether account menus can be displayed
     * If user is employee and associated with customer, display the account menus
     * Else if user is employee but not associated, hide the menus
     * Else show account menu for guest and customers
     */
    isNotAnEmployee() {
      let isCustomerAvailable = true;
      if (this.isEmployee) {
        if (this.isCustomer) {
          isCustomerAvailable = true;
        } else {
          isCustomerAvailable = false;
        }
      }
      return isCustomerAvailable;
    },
  },
  methods: {
    /**
     * Method for replicateNow functionality
     */
    async replicateNow() {
      try {
        const response = await axios.get('api/employee/rn', { timeout: 120000 });
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'success',
            title: 'Data Replication',
            message: response.data.message,
          },
          timeout: 7000,
        });
      } catch (error) {
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: 'Error',
            message: error.message,
          },
          timeout: 7000,
        });
      }
    },
    async resetIndex() {
      try {
        const response = await ModalService.open(WCResetIndexModal);
        if (response === MODAL_EVENTS.CONFIRM) {
          await axios.post('api/employee/reset-index');
        }
      } catch (error) {
        console.error(error);
        if (error.response?.data?.message) {
          ToastService.open(WCSimpleToast, {
            props: {
              variant: 'danger',
              title: 'Error',
              message: error.response.data.message,
            },
            timeout: 10000,
          });
        }
      }
    },
  },
};
