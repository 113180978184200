<template>
  <div class="d-flex flex-row p-3 justify-content-between align-items-center">
    <div class="d-flex flex-row align-items-center">
      <WCImage
        :imageSrc="coupon.imageUrl || ''"
        :imageAlt="coupon.name"
        imageClass="w-25 h-25 p-1 wc-coupon-image"
        defaultClass="w-25 h-25 p-1 wc-coupon-image"
        :defaultTitle="coupon.name"
      />
      <div class="d-flex flex-column">
        <div
          :aria-label="`Coupon ${coupon.name}`"
          data-testid="couponTitle"
          class="btn link-dark underline-link-from-center wc-coupon-name font-size-xs"
          @click="handleModalSwap"
        >
          {{ couponTitle }}
        </div>
        <div
          v-if="!couponDisplayable(coupon)"
          class="text-danger font-size-xs mr-1"
          data-testid="coupon-not-available-text"
        >
          <font-awesome-icon class="mr-1 text-danger " icon="circle-exclamation" size="lg" />
          <span>{{ $t('couponNotAvailable') }}</span>
        </div>
      </div>
    </div>

    <WCCouponClipButton
      class="wc-coupon-card__bottom-button wc-coupon-card__bottom-button-detail"
      :couponId="coupon.id"
      :availableLabel="$t('clipCoupon')"
      :clippedLabel="$t('clippedCoupon')"
      :redeemedLabel="$t('couponRedeemed')"
      :disabled="false"
      :isClipped="evaluateClipStatus"
      :isRedeemed="evaluateRedeemedStatus"
      :handleClip="handleClip"
    />
  </div>
</template>

<script lang="ts">
import WCImage from '@/components/WCImage/WCImage.vue';
import CouponAvailabilityMixin from '@/modules/coupons/mixins/CouponAvailabilityMixin';
import WCCouponClipButton from '@/components/WCCouponClipButton/WCCouponClipButton.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import { defineComponent } from 'vue';
import CouponMixin from '../../mixins/CouponMixin';

export default defineComponent({
  mixins: [ModalMixin, CouponMixin, CouponAvailabilityMixin],
  components: {
    WCImage,
    WCCouponClipButton,
  },
  methods: {
    handleModalSwap() {
      this.close();
      this.openMoreInfoModal();
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-coupon-detail__name {
  font-size: 1rem;
}

.wc-coupon-card__bottom-button-detail {
  max-width: 40%;
}

.wc-coupon-name {
  margin: 0 !important;
  padding: 0 !important;
  text-align: left;
}

.wc-coupon-image {
  min-width: 3.5rem;
  max-width: 3.5rem;
  min-height: 3.5rem;
  max-height: 3.5rem;
}
</style>
