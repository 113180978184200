<template>
  <div v-if="isMultiStore && isNotAnEmployee" class="d-flex align-items-center">
    <router-link
      class="d-flex location rounded-circle align-items-center justify-content-center bg-secondary mr-2"
      to="/select-store"
    >
      <font-awesome-icon icon="location-dot" size="lg" />
    </router-link>
    <div class="d-flex flex-column justify-content-center">
      <router-link
        to="/select-store"
        class="text-body font-size-sm lh-1 underline-link-from-center fit-content text-break"
        data-testid="storeName"
      >
        {{ storeName }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapGetters } from 'vuex';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';

export default {
  components: [FontAwesomeIcon],
  mixins: [EmployeeMixin],
  computed: {
    ...mapGetters({ getUser: 'user/getUser' }),
    storeName() {
      return this.$configuration.store.name;
    },
    isMultiStore() {
      return this.$configuration.multistore;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.location {
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  color: var(--secondary-contrast);
}

.fit-content {
  width: fit-content;
}

.lh-1 {
  line-height: 1;
}
</style>
