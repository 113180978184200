import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_PlatformRenderer = _resolveComponent("PlatformRenderer")!

  return (_openBlock(), _createBlock(_component_PlatformRenderer, null, {
    onDesktop: _withCtx(() => [
      _createElementVNode("button", {
        class: "btn justify-content-between align-items-center",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.customize())),
        ref: "customizeBtnRef"
      }, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "ml-2" }, null, -1)),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('customize')) + " ", 1),
        _createVNode(_component_font_awesome_icon, {
          icon: "wand-magic-sparkles",
          class: "ml-2",
          size: "sm"
        })
      ], 512)
    ]),
    onMobile: _withCtx(() => [
      _createElementVNode("button", {
        class: "unstyled-btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToCustomizePage())),
        "aria-label": _ctx.$t('customize'),
        ref: "customizeBtnRef"
      }, [
        _createVNode(_component_font_awesome_icon, {
          class: "btn-link",
          icon: "wand-magic-sparkles",
          size: "lg"
        })
      ], 8, _hoisted_1)
    ]),
    _: 1
  }))
}