<template>
  <WCModal position="center" @dismiss="dismiss(MODAL_EVENTS.DISMISS)">
    <WCModalHeader class="align-items-baseline">
      <div class="font-size-xl font-weight-bold">
        <font-awesome-icon class="mr-2" :icon="['fa', 'trash']" />
        {{ $t('deleteAccount') }}
      </div>
    </WCModalHeader>

    <WCModalBody>
      <div class="text-center">
        <div class="font-weight-bold my-2 mx-4" data-testid="areYouSureLabel">
          {{ $t('areYouSureDeleteAccount') }}
        </div>
        <div class="mx-1">
          <span class="font-weight-bold">{{ $t('attention') }}! </span>
          <span>{{ $t('requestRemovalDisclaimer') }}</span>
        </div>
      </div>
    </WCModalBody>

    <WCModalFooter>
      <div class="m-auto w-100 text-center">
        <button
          @click="close(MODAL_EVENTS.CONFIRM)"
          class="btn btn-danger wc-delete-account-button mr-3"
          data-testid="yesDeleteLabel"
        >
          {{ $t('yesDelete') }}
        </button>

        <button
          @click="dismiss(MODAL_EVENTS.CANCEL)"
          class="btn btn-outline-danger wc-delete-account-button"
          data-testid="noDontDeleteLabel"
        >
          {{ $t('noDontDelete') }}
        </button>
      </div>
    </WCModalFooter>
  </WCModal>
</template>

<script lang="ts">
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import { MODAL_EVENTS } from '@/constants/EventConstants';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [ModalMixin],
  components: { WCModal, WCModalHeader, WCModalBody, WCModalFooter },
  data() {
    return {
      MODAL_EVENTS,
    };
  },
});
</script>
<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-delete-account-button {
  flex-grow: 1;
  width: 40%;
}
</style>
