import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-100"
}
const _hoisted_2 = ["action", "target"]
const _hoisted_3 = ["title", "name", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WCEbtCardEntry = _resolveComponent("WCEbtCardEntry")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.numLoads === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_WCEbtCardEntry, {
            onCardCaptured: _ctx.handleCardCaptured,
            onError: _ctx.propagateError,
            token: _ctx.token,
            submitText: _ctx.$t(_ctx.submitText),
            message: _ctx.$t(_ctx.message)
          }, null, 8, ["onCardCaptured", "onError", "token", "submitText", "message"]),
          _cache[7] || (_cache[7] = _createTextVNode()),
          _createElementVNode("form", {
            action: _ctx.iframeSrc,
            method: "post",
            target: _ctx.pinpadIframeId,
            ref: _ctx.pinpadIframeId,
            style: {"display":"none"}
          }, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              id: "AccuId",
              name: "AccuId",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accuId) = $event))
            }, null, 512), [
              [_vModelText, _ctx.accuId]
            ]),
            _cache[2] || (_cache[2] = _createTextVNode()),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              id: "AccuReturnURL",
              name: "AccuReturnURL",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.returnURL) = $event))
            }, null, 512), [
              [_vModelText, _ctx.returnURL]
            ]),
            _cache[3] || (_cache[3] = _createTextVNode()),
            _cache[4] || (_cache[4] = _createElementVNode("input", {
              type: "text",
              id: "AccuLanguage",
              name: "AccuLanguage",
              value: "en-US"
            }, null, -1)),
            _cache[5] || (_cache[5] = _createTextVNode()),
            _cache[6] || (_cache[6] = _createElementVNode("input", {
              type: "submit",
              value: "Submit"
            }, null, -1))
          ], 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _cache[8] || (_cache[8] = _createTextVNode()),
    (_ctx.numLoads < 2)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 1,
          title: _ctx.iframeTitle,
          name: _ctx.pinpadIframeId,
          id: _ctx.pinpadIframeId,
          style: _normalizeStyle({ height: _ctx.iframeSrc ? '40rem' : '0' })
        }, null, 12, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}